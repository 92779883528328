<template>
  <section class="filters-header">
    <section class="d-flex justify-space-between align-center py-8 px-2" style="height:45px">
      <div class="d-flex">
        <v-icon color="var(--secondary-color)" size="30" class="mr-2"
          >mdi-filter-variant</v-icon
        >
        <h3 style="color: var(--secondary-color)">Filters</h3>
      </div>
      <section class="actions mt-2 d-flex justify-end " v-if="hasFilter" >
        <v-btn
          color="var(--secondary-color)"
          class="reset-btn"
          icon
          @click="resetFilters"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-btn
          icon
          color="var(--secondary-color)"
          class="apply-btn"
          @click="submitFilters"
          ><v-icon>mdi-check</v-icon></v-btn
        >
      </section>
    </section>
    <section class="ex-panels ">
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>Country</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedCategory"
              @change="fetchSubCategories"
              :items="categoryItems"
              item-text="title"
              item-value="id"
              label="Countries List"
              solo
              dense
              clearable
              hide-details
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>City</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedSubCategory"
              :items="subCategoryItems"
              :disabled="!selectedCategory"
              :loading="!!selectedCategory && subCategoryItems.length <= 0"
              item-text="title"
              item-value="id"
              :label="selectedCategory ? 'City List' : 'Select a Country First'"
              solo
              dense
              clearable
              hide-details
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Specification</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="selectedSpecifications"
              :items="existSpecifications"
              item-text="title"
              item-value="id"
              class="Specifications-select"
              label="Specifications List"
              solo
              dense
              clearable
              multiple
              chips
              small-chips
              deletable-chips
              hide-details
            ></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <v-expansion-panel>
          <v-expansion-panel-header>Size</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1 class="white--text mb-3">Width:</h1>
            <v-text-field
              :value="widthRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(widthRange, 1, $event)"
            ></v-text-field>
            <v-text-field
              :value="widthRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(widthRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="widthRange"
              :min="minWidth"
              :max="maxWidth"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ minWidth }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-1">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ maxWidth }}</p>
              </div>
            </div>

            <v-divider class="white my-4"></v-divider>

            <h1 class="white--text mb-3">height:</h1>
            <v-text-field
              :value="heightRange[0]"
              hide-details
              outlined
              dense
              type="number"
              label="Minimum"
              clearable
              dark
              @change="$set(heightRange, 1, $event)"
            ></v-text-field>
            <v-text-field
              :value="heightRange[1]"
              class="my-2"
              hide-details
              outlined
              dense
              type="number"
              label="Maximum"
              clearable
              dark
              @change="$set(heightRange, 1, $event)"
            ></v-text-field>
            <v-range-slider
              v-model="heightRange"
              :min="minHeight"
              :max="maxHeight"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ minHeight }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-1">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ maxHeight }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel> -->

        <!-- <v-expansion-panel>
          <v-expansion-panel-header>Name</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              v-model="name"
              label="name"
              type="text"
              outlined
              hide-details
              dense
              clearable
              dark
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
        <v-expansion-panel>
          <v-expansion-panel-header>Price</v-expansion-panel-header>
          <v-expansion-panel-content>
            
            <v-range-slider
              v-model="priceRange"
              :max="priceMax"
              :min="priceMin"
              hide-details
              class="align-center green--text"
              color="#FFD754"
            >
            </v-range-slider>
            <div class="d-flex justify-space-between align-center">
              <div style="width: 40%">
                <v-text-field
                  :value="priceRange[0]"
                  hide-details
                  solo
                  type="number"
                  dark
                  class="input"
                  @change="$set(priceRange, 0, $event)"
                ></v-text-field>
              </div>
              <span class="white--text">-</span>
              <div style="width: 40%">
                <v-text-field
                  :value="priceRange[1]"
                  hide-details
                  solo
                  type="number"
                  dark
                  class="input"
                  @change="$set(priceRange, 1, $event)"
                ></v-text-field>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Location</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex align-center justify-space-between">
              <label for="" class="white--text"> Coordiante X</label>
              <v-text-field
                v-model="cordinateXSelected"
                type="number"
                solo
                hide-details
                dense
                clearable
                dark
                class="input"
                style="max-width: 80px"
              ></v-text-field>
            </div>
            <v-slider
              v-model="cordinateXSelected"
              class="align-center"
              :max="cordinateXMax"
              :min="cordinateXMin"
              hide-details
              color="#FFD754"
            >
            </v-slider>
            <div class="d-flex justify-space-between mb-4">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ cordinateXMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-2">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ cordinateXMax }}</p>
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex align-center justify-space-between">
              <label for="" class="white--text"> Coordiante Y</label>

              <v-text-field
                v-model="cordinateYSelected"
                label="Cordinate Y"
                type="number"
                class="input"
                solo
                hide-details
                dense
                clearable
                dark
                style="max-width: 80px"
              ></v-text-field>
            </div>
            <v-slider
              v-model="cordinateYSelected"
              class="align-center"
              :max="cordinateYMax"
              :min="cordinateYMin"
              hide-details
              color="#FFD754"
            >
            </v-slider>
            <div class="d-flex justify-space-between mb-4">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ cordinateXMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-2">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ cordinateYMax }}</p>
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex align-center justify-space-between">
              <label for="" class="white--text"> Radius </label>
              <v-text-field
                v-model="selectedRadius"
                label="Radius"
                type="number"
                solo
                class="input"
                hide-details
                dense
                clearable
                dark
                style="max-width: 80px"
              ></v-text-field>
            </div>
            <v-slider
              v-model="selectedRadius"
              class="align-center"
              :max="radiusMax"
              :min="radiusMin"
              hide-details
              color="#FFD754"
            >
            </v-slider>
            <div class="d-flex justify-space-between mb-4">
              <div style="width: 60px" class="d-flex">
                <p class="grey--text mr-1">Min:</p>
                <p class="white--text">{{ radiusMin }}</p>
              </div>
              <div style="width: 60px" class="d-flex mr-2">
                <p class="grey--text">Max:</p>
                <p class="white--text">{{ radiusMax }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      panel: [0, 1, 2, 3],
      categoryItems: [],
      selectedCategory: 0,
      subCategoryItems: [],
      selectedSubCategory: 0,
      // Specification
      selectedSpecifications: [],
      existSpecifications: [],
      // Specification
      // size
      // minWidth: 0,
      // maxWidth: 16,
      // widthRange: [0, 16],
      // minHeight: 0,
      // maxHeight: 16,
      // heightRange: [0, 16],
      // size
      // name: null,

      // location
      cordinateXMin: 0,
      cordinateXMax: 0,
      cordinateXSelected: 0,

      cordinateYMin: 0,
      cordinateYMax: 0,
      cordinateYSelected: 0,

      radiusMin: 0,
      radiusMax: 1000,
      selectedRadius: 0,
      // location

      // price
      priceMin: 0,
      priceMax: 23300,
      priceRange: [0, 23300]
      // price
    };
  },
  created() {
    this.fetchCategories();
    this.fetchTags();
    this.fetchMaxCordinate();
    this.fetchPriceRange();
  },
  methods: {
    ...mapActions([
      "getCategories",
      "getSubCategories",
      "getTags",
      "getMaxCordinate",
      "getPriceRange",
    ]),
    fetchCategories() {
      this.getCategories().then((result) => {
        this.categoryItems = result.result;
        console.log("cI:", this.categoryItems);
      });
    },
    fetchSubCategories() {
      if (this.selectedCategory) {
        this.getSubCategories(this.selectedCategory).then((result) => {
          this.subCategoryItems = result.result;
        });
      }
    },
    fetchTags() {
      this.getTags().then((result) => {
        this.existSpecifications = result.result;
      });
    },
    fetchMaxCordinate() {
      this.getMaxCordinate().then((result) => {
        console.log(result);
        this.cordinateXMax = result.result.maxCoordinateX;
        this.cordinateYMax = result.result.maxCoordinateY;
      });
    },
    fetchPriceRange() {
      this.getPriceRange().then((result) => {
        this.priceMin = result.result.minPrice;
        this.priceMax =  result.result.maxPrice;
        this.priceRange[1] = result.result.maxPrice ;
        console.log(this.priceRange);
      });
    },
    submitFilters() {
      this.$emit(
        "submitFilters",
        this.selectedSubCategory,
        // this.name,
        this.cordinateXSelected,
        this.cordinateYSelected,
        this.selectedRadius,
        this.priceRange,
        this.selectedSpecifications
      );
    },
    resetFilters() {
      this.selectedCategory = 0;
      this.selectedSubCategory = 0;
      // this.name = null
      this.cordinateXSelected = 0;
      this.cordinateYSelected = 0;
      this.selectedRadius = 0;
      this.priceRange = [0, 23300];
      this.selectedSpecifications = [];
      this.submitFilters()
    },
  },
  computed: {
    hasFilter() {
      if (
        this.selectedSubCategory !== 0 ||
        this.cordinateXSelected !== 0 ||
        this.cordinateYSelected !== 0 ||
        this.selectedRadius !== 0 ||
        this.priceRange[0] !== 0 ||
        this.priceRange[1] !== 23300 ||
        this.selectedSpecifications.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-btn {
  position: relative;
  &:hover {
    &::after {
      display: block;
    }
  }
  &:after {
    content: "Reset Filters";
    position: absolute;
    padding: 5px 10px;
    top: -35px;
    right: -50px;
    background: var(--base-color);
    z-index: 2;
    border-radius: 5px;
    display: none;
  }
}
.apply-btn {
  position: relative;
  &:hover {
    &::after {
      display: block;
    }
  }
  &:after {
    content: "Apply Filters";
    position: absolute;
    padding: 5px 10px;
    top: -35px;
    right: -50px;
    background: var(--base-color);
    z-index: 2;
    border-radius: 5px;
    display: none;
  }
}
.ex-panels {
  width: 100%;
  height:85vh;
  // max-height: 650px;
  overflow: auto;
  @media only screen and (max-width: 1250px){
    max-height: 450px;
  }
  .v-expansion-panels {
    .v-expansion-panel-header {
      border-radius: 0 !important;
      background: var(--background-color) !important;
      color: var(--text-color);
      user-select: none;
      min-height: 40px;
      height: 40px;
    }
    ::v-deep .v-expansion-panel-header__icon {
      i {
        &::before {
          color: var(--text-color) !important;
        }
      }
    }
    ::v-deep.v-expansion-panel-content__wrap {
      background: var(--background-color) !important;
      padding: 5px 15px;
      border-radius: 0;
      // box-shadow: 0 0 5px 5px var(--background-color);
      border-bottom: 1px solid var(--text-color);
    }
    .Specifications-select {
      ::v-deep .v-select__selections {
        margin-top: 5px;
      }
    }
    .v-select {
      font-size: 10px;
      ::v-deep .v-label {
        color: var(--secondary-color);
        font-weight: 900;
        font-size: 12px;
      }
      ::v-deep .v-icon {
        color: var(--secondary-color);
      }
      ::v-deep .v-input__slot {
        background: var(--base-color);
        .v-select__selection--comma {
          color: var(--secondary-color);
        }
      }
      ::v-deep .v-input__control {
        min-height: 28px;
      }
    }
    .input {
      ::v-deep .v-input__control {
        min-height: 22px;
        height: 22px;
        color: var(--base-color);
      }
      ::v-deep .v-input__slot {
        background: var(--third-color);
        font-size: 12px;
      }
      ::v-deep input {
        color: var(--base-color);
        font-weight: 900;
      }
    }
  }
  label {
    font-size: 12px;
    white-space: nowrap;
  }
  p {
    font-size: 13px;
  }

  &::-webkit-scrollbar {
  width: 4px !important;
}
}
</style>
