<template>
  <section class="shop">
    <!-- main-shop -->

    <section class="main-shop mx-auto">
      <!-- tabs -->
      <div class="tabs">
        <ul class="d-flex pa-0">
          <li
            v-for="(item, i) in tabs"
            :key="i"
            @click="changeTabs(item)"
            :class="item.isActive ? 'active' : ''"
          >
          
          <i :class="`fa-solid ${item.icon}`" class="tab-icon"></i>
            <span >{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <Lands></Lands>
      <!-- <LandCard
        :lands="lands"
        @buy="buyLand"
        :buyLoading="buyLoading"
        :quastionDialog="quastionDialog"
        v-else
      /> -->
    </section>
    <!-- main-shop -->
    
  </section>
</template>
<script>
import Lands from "../../components/shop/lands/Lands.vue";
export default {
  data() {
    return {
      //tabs
      tabs: [
        {
          name: "Lands",
          icon:"fa-map-location-dot",
          isActive: true,
        }/*,
        {
          name: "Lootboxes",
          icon:"fa-gift",
          isActive: false,
        },
        {
          name: "Buildings",
          icon:"fa-building",
          isActive: false,
        },
        {
          name: "packs",
          icon:"fa-box",
          isActive: false,
        },
        {
          name: "currencies",
          icon:"fa-coins",
          isActive: false,
        },*/
      ],
      previousTab: {},
    };
  },
  components: {
    Lands,
  },
  
  created() {
    //get session tab name and find it and change tab to that
    if (sessionStorage.getItem("shop-tab")) {
      this.previousTab = this.tabs.find(
        (element) => element.name === sessionStorage.getItem("shop-tab")
      );
      this.changeTabs(this.previousTab);
    }
  },

  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.tabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.tabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.tabs.find((element) => element.isActive === true);
      sessionStorage.setItem("shop-tab", this.previousTab.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.shop {
  border: 1px solid rgb(128, 128, 128);
  margin: 105px auto 0 auto;
  width: 100%;
  max-width: 1800px;
  
  .tabs{
    margin-left: 17.1%;
    @media only screen and(max-width: 1070px){
      margin-left: 18.5%;
    }
    @media only screen and(max-width: 980px) {
      margin-left: 0;
    }
    ul{
      li{
        .tab-icon{
          font-size: 18px;
          margin-right: 5px;
        }
        @media only screen and(max-width: 420px) {
        span{
          display: none;
        }
      }
      }
    }
    
  }
}
</style>
