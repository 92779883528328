<template>
  <section class="mobile-filters ">
    <v-btn @click="drawer = !drawer" icon small class="toggle-btn">
      <v-icon>mdi-filter</v-icon>
    </v-btn>

    <v-navigation-drawer v-model="drawer" absolute class="nav-drawer">
      <LandFilters @submitFilters="submitFiltersMobile" class="mt-10"/>
    </v-navigation-drawer>
  </section>
</template>

<script>
import LandFilters from "./LandFilters.vue";
export default {
  data() {
    return {
      drawer: false,
    };
  },
  components: { LandFilters },
  methods: {
    submitFiltersMobile(
      selectedSubCategory,
      // name,
      cordinateXSelected,
      cordinateYSelected,
      selectedRadius,
      priceRange,
      selectedSpecifications
    ) {
      this.drawer = false;
      this.$emit(
        "submitFilters",
        selectedSubCategory,
        cordinateXSelected,
        cordinateYSelected,
        selectedRadius,
        priceRange,
        selectedSpecifications
      );
    },
  },
};
</script>

<style lang="scss">
.mobile-filters{
  .nav-drawer {
    z-index: 220 !important;
    background: var(--background-color) !important;
  }
  .toggle-btn{
    color: var(--secondary-color);
    margin-left: 10px;
    transition: all .3s linear;
    &:hover{
      background: var(--secondary-color);
      box-shadow: 0 0 12px var(--secondary-color);
      .v-icon{
        color: var(--background-color);
      }
    }
    @media only screen and(min-width:980px) {
      display: none;
    }
    .v-icon{
      color: var(--secondary-color);
      margin-top: 2px;
    }
  }
  
  }
</style>
