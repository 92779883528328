<template>
  <section class="sort-box px-5 w-100">
    <v-row class="white--text align-center" no-gutters>
      <!-- <v-col cols="6" md="2">
          <v-btn
            class="my-3 mr-10 white--text"
            outlined
            @click="toggleDrawer"
          >
            <v-icon left>mdi-filter</v-icon>Filters</v-btn
          >
        </v-col> -->
      <v-col cols="10" class="d-md-flex">
        <h3 class="mr-2 sort-text">
          <v-icon  class="mb-1 sort-text">mdi-sort-ascending</v-icon> Sort By
          :
        </h3>

        <!-- <v-menu open-on-hover bottom offset-y dark>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" text>
                Size
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-btn text @click="makeSort('size_id', 'asc')">Minmum size</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn text @click="makeSort('size_id', 'desc')">Maximum size</v-btn>
              </v-list-item>
            </v-list>
          </v-menu> -->

        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'price' && activeSort.sort === 'desc'
              ? 'active'
              : ''
          "
          @click="makeSort('price', 'desc')"
          >Highest Price</v-btn
        >
        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'price' && activeSort.sort === 'asc'
              ? 'active'
              : ''
          "
          @click="makeSort('price', 'asc')"
          >Lowest Price</v-btn
        >
        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'updated_at' && activeSort.sort === 'asc'
              ? 'active'
              : ''
          "
          @click="makeSort('updated_at', 'asc')"
          >Newest</v-btn
        >
        <v-btn
          small
          class="sort-item-btn"
          :class="
            activeSort.order === 'updated_at' && activeSort.sort === 'desc'
              ? 'active'
              : ''
          "
          @click="makeSort('updated_at', 'desc')"
          >Oldest</v-btn
        >
        
      </v-col>
      <v-col cols="2">
        <div class="mr-3 text-end">
          {{ total }} <span class="">Lands</span>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: ["total"],
  data() {
    return {
      activeSort: "none",
    };
  },

  methods: {
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
    makeSort(order, sort) {
      if (this.activeSort.order === order && this.activeSort.sort === sort) {
        this.activeSort = 'none';
        this.$emit("resetSort");
      } else {
        this.$emit("makeSort", order, sort);
        this.activeSort = { order: order, sort: sort };
      }
      console.log(this.activeSort);
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-text{
  color: var(--text-color);
}
.sort-item-btn {
  background: var(--base-color) !important;
  color: var(--secondary-color);
  font-weight: 900;
  margin: 0 5px;
  transition: all .4s ease;
  &:hover{

    box-shadow: var(--secondary-color) 0px 0px  5px;
  }
  &.active {
    background: var(--secondary-color) !important;
    color: var(--base-color);
    box-shadow: var(--secondary-color) 0px 0px 10px;
  }
}
</style>
